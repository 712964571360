@import '_settings.scss';
@import '_base.scss';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
#ping-base {
  height: 100vh;
}
